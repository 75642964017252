<script>
import {defineComponent} from 'vue'
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import OrdersApi from "@/api/orders/orders_api";
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CreateCategory",
  emits: ['update'],
  props: {
    product_id: {
      type: Number,
      required: true
    },
    counterparty_id: {
      type: Number,
      required: true
    }
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      category_value: {},
      category_price: '',
      categories_list: [],
    }
  },
  methods: {
    async getCategories() {
      let api = new OrdersApi()
      let response = await api.getCategoryList()
      this.categories_list = response.results.map(c => {
        return {
          value: c.id,
          label: c.name,
        }
      })
    },
    async saveCategory() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.post(`/counterparty/product/details/${this.product_id}/create/`, {
            price: this.category_price,
            category_id: this.category_value.value,
            counterparty_id: this.counterparty_id
        })
        this.$emit('update')
        await Toast.fire({
          icon: "success",
          title: "Category added",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong !",
        })
      }
    }
  },
  created() {
    this.getCategories()
  }
})
</script>

<template>
  <form @submit.prevent="saveCategory()">
    <b-row>
      <b-col sm="12" lg="7">
        <label class="form-label">Name</label>
        <Multiselect
            :object="true"
            v-model="category_value"
            :options="categories_list"
            placeholder="Category name"
            :searchable="true"
        />
      </b-col>
      <b-col sm="12" lg="5">
        <label class="form-label">Price</label>
        <input placeholder="Price"
               step="any" type="number" class="form-control mb-3" v-model="category_price">
      </b-col>
      <b-col sm="12">
        <b-button type="submit" class="w-100" variant="success" size="sm">Add</b-button>
      </b-col>
    </b-row>
  </form>
</template>

<style scoped>

</style>