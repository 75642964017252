<script>
import {defineComponent} from "vue";
import ShipmentProductsList from "./components/ShipmentList.vue";
import ShipmentProductDetails from "./components/ShipmentDetails.vue";

export default defineComponent({
  name: "todo",
  data() {
    return {
      current_product: null
    }
  },
  methods: {},
  computed: {},
  components: {
    ShipmentProductsList,
    ShipmentProductDetails,
  }
})
</script>

<template>
  <b-row>
    <b-col lg="3">
      <b-card no-body>
        <b-card-body>
          <ShipmentProductsList @onSelect="current_product = $event"/>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col lg="9">
      <ShipmentProductDetails :current_product="current_product"/>
    </b-col>
  </b-row>
</template>