<script>
import {defineComponent} from 'vue'
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import OrdersApi from "@/api/orders/orders_api";
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "UpdateCategory",
  emits: ['update'],
  props: {
    category: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      category_value: {},
      category_price: '',
      categories_list: [],
    }
  },
  methods: {
    async getCategories() {
      let api = new OrdersApi()
      let response = await api.getCategoryList()
      this.categories_list = response.results.map(c => {
        return {
          value: c.id,
          label: c.name,
        }
      })
    },
    async saveCategory() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.put(`/counterparty/product/details/${this.category.id}/update/`, {
          price: this.category_price,
          category_id: this.category_value.value
        })
        this.$emit('update')
        await Toast.fire({
          icon: "success",
          title: "Category updated",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong !",
        })
      }
    }
  },
  watch: {
    category: {
      handler: function (newVal) {
        if (!newVal) return
        this.category_value = {
          value: newVal.category_id,
          label: newVal.category_name,
        }
        this.category_price = newVal.price
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getCategories()
  }
})
</script>

<template>
  <form @submit.prevent="saveCategory()">
    <b-row>
      <b-col sm="12" lg="7">
        <label class="form-label">Name</label>
        <Multiselect
            :object="true"
            v-model="category_value"
            :options="categories_list"
            :searchable="true"
        />
      </b-col>
      <b-col sm="12" lg="5">
        <label class="form-label">Price</label>
        <input step="any" type="number" class="form-control mb-3" v-model="category_price">
      </b-col>
      <b-col sm="12">
        <b-button type="submit" class="w-100" variant="success" size="sm">Save</b-button>
      </b-col>
    </b-row>
  </form>
</template>

<style scoped>

</style>