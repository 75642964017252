<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "ProductCreate",
  emits: ['update'],
  data() {
    return {
      product_name: '',
      container_type: '',
      container_types: [
        {value: '20', label: '20'},
        {value: '20HC', label: '20HC'},
        {value: '40', label: '40'},
        {value: '40HC', label: '40HC'},
        {value: '45', label: '45'},
      ]
    }
  },
  components: {
    Multiselect
  },
  methods: {
    async createShipmentProduct() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.post('/counterparty/product/create/', {
          name: this.product_name,
          container_type: this.container_type
        })
        document.querySelector('#CreateShipmentProduct .btn-close').click()
        this.$emit('update')
        await Toast.fire({
          icon: "success",
          title: "Shipment product created",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Error",
        })
      }
    }
  }
})
</script>

<template>
  <b-button data-bs-toggle="modal" data-bs-target="#CreateShipmentProduct"
            variant="success" class="w-100">
    Add
  </b-button>

  <div id="CreateShipmentProduct" class="modal fade" tabindex="-1" aria-hidden="true"
       style="display: none;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Create Shipment Product
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createShipmentProduct()">

            <b-row>
              <b-col sm="12" md="6">
                <label class="form-label">Shipment Product Name</label>
                <input v-model="product_name" class="form-control mb-3" type="text" placeholder="Shipment product name">
              </b-col>
              <b-col sm="12" md="6">
                <label class="form-label">Container Type</label>
                <Multiselect
                    v-model="container_type"
                    :options="container_types"
                    :searchable="true"
                >

                </Multiselect>
              </b-col>
            </b-row>

            <div>
              <b-button type="submit" variant="success" class="w-100">Create</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>