<script>
import {defineComponent} from 'vue'
import axios from "axios";
import UpdateCategory from "../components/UpdateCategory.vue";
import CreateCategory from "../components/CreateCategory.vue";
import Swal from "sweetalert2";
import CounterpartyCreate from "../modals/CounterpartyCreate.vue";

export default defineComponent({
  name: "ShipmentDetails",
  props: {
    current_product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      details: [],
      request_status: 'loading',
      current_counterparty: {}
    }
  },
  components: {
    CounterpartyCreate,
    UpdateCategory,
    CreateCategory,
  },
  methods: {
    async getShipmentDetails() {
      this.request_status = 'loading'
      try {
        let response = await axios.get(`/counterparty/product/${this.current_product.id}/`)
        this.details = response.data.shipment_details
        this.request_status = 'success'
      } catch {
        this.details = []
        this.request_status = 'error'
      }
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();

      var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
      var to = 'aaaaeeeeiiiioooouuuunc------';

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-');

      return str;
    },
    async deleteCounterparty(product) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {

        for (const category of product.categories) {
          await axios.delete(`/counterparty/product/details/${category.id}/delete/`)
        }
        await this.getShipmentDetails()
        await Toast.fire({
          icon: "success",
          title: "Shipment Product deleted",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong !",
        })
      }
    },
    async deleteCategory(id) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.delete(`/counterparty/product/details/${id}/delete/`)
        await this.getShipmentDetails()
        await Toast.fire({
          icon: "success",
          title: "Category deleted",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong !",
        })
      }
    },
  },
  watch: {
    current_product: {
      handler: function (newVal) {
        if (!newVal) return
        this.getShipmentDetails()
      },
      deep: true,
      immediate: true
    }
  }
})
</script>

<template>

  <CounterpartyCreate
      v-if="request_status === 'success'"
      :product_id="current_product.id"
      @update="getShipmentDetails()"
  />

  <!--  <CounterpartyUpdate :counterparty="current_counterparty"/>-->

  <b-card no-body>
    <b-card-body>
      <b-row gutter-y="3">
        <b-col sm="12">
          <h5 class="mb-0">Shipment Product Details</h5>
        </b-col>
        <b-col sm="12">
          <b-row gutter-y="3">
            <div class="col-lg">
              <div class="search-box"><input type="text" id="searchTaskList" class="form-control search"
                                             placeholder="Search counterparty name">
                <i class="ri-search-line search-icon"></i>
              </div>
            </div>
            <div class="col-lg-auto">
              <button data-bs-toggle="modal"
                      data-bs-target="#CreateShipmentCounterparty"
                      class="btn btn-primary btn-md createTask" type="button"><!---->
                <div class="btn-content">
                  <i class="ri-add-fill align-bottom"></i>
                  Add Counterparty
                </div>
              </button>
            </div>
          </b-row>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>

  <template v-if="request_status === 'loading'">
    <div class="text-center py-4">
      <b-spinner></b-spinner>
    </div>
  </template>
  <template v-else-if="request_status === 'success'">
    <b-row gutter-y="1" v-if="details.length > 0">
      <b-col sm="12" md="6" xl="4" v-for="(product, i) in details" :key="product">
        <b-card no-body>
          <div class="card-header bg-soft-info d-flex justify-content-between"
          >
            <div>
              <h5 data-bs-toggle="collapse"
                  :data-bs-target="`#collape${slugify(product.counterparty_name)}_${i}`"
                  class="card-title text-uppercase cursor-pointer fw-semibold mb-1 fs-15">
                {{ product.counterparty_name }}
              </h5>
              <p class="text-muted mb-0">
                {{ product.categories.length }} <span class="fw-medium"> Categories</span></p>
            </div>
            <div>
              <i @click="deleteCounterparty(product)"
                 class="bx bxs-trash-alt text-danger fs-18 c_icon_hoverable ms-2"></i>
            </div>
          </div>
        </b-card>
        <b-collapse :id="`collape${slugify(product.counterparty_name)}_${i}`">
          <b-row class="mb-3">

            <b-col sm="12">
              <div data-bs-toggle="collapse"
                   :data-bs-target="`#collapse_category_create${slugify(product.counterparty_name)}_${i}`"
                   class="border border-success border-dashed text-center cursor-pointer rounded-3 py-3"
              >
                <label class="form-label fs-14 mb-0">
                  + ADD NEW CATEGORY
                </label>
              </div>
              <b-collapse :id="`collapse_category_create${slugify(product.counterparty_name)}_${i}`"
                          class="border-top border-top-dashed">
                <b-card no-body class="mb-1">
                  <b-card-body>
                    <CreateCategory
                        @update="getShipmentDetails()"
                        :counterparty_id="product.counterparty_id"
                        :product_id="current_product.id"/>
                  </b-card-body>
                </b-card>
              </b-collapse>
            </b-col>

            <b-col sm="12"
                   v-for="category in product.categories" :key="category"
            >
              <b-card no-body class="mb-1">
                <b-card-body
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 ">
                      <div class="avatar-xs me-2" data-bs-toggle="collapse"
                           :data-bs-target="`#collapse_category_${slugify(category.category_name)}_${i}`">
                        <div class="avatar-title rounded-circle bg-soft-secondary text-secondary">
                        <span class="fs-18">
                          {{ category.category_name.slice(0, 1) }}
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <div class="d-flex justify-content-between">
                        <div style="max-width: 200px" data-bs-toggle="collapse"
                             :data-bs-target="`#collapse_category_${slugify(category.category_name)}_${i}`">
                          <h6 class="text-truncate fs-14 mb-1">{{ category.category_name }}</h6>
                          <p class="text-muted mb-0">${{ category.price }}</p>
                        </div>
                        <div>
                          <i data-bs-toggle="collapse"
                             :data-bs-target="`#collapse_category_${slugify(category.category_name)}_${i}`"
                             class=" bx bxs-chevron-down fs-20 c_icon_hoverable"></i>
                          <i @click="deleteCategory(category.id)"
                             class="bx bxs-trash-alt text-danger fs-18 c_icon_hoverable ms-2"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <b-collapse class="border-top border-top-dashed"
                            :id="`collapse_category_${slugify(category.category_name)}_${i}`">
                  <b-card-body>
                    <UpdateCategory
                        @update="getShipmentDetails()"
                        :category="category"/>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>
    <template v-else>List is empty</template>
  </template>
  <template v-if="request_status === 'error'">
    <div class="text-center py-4">
      Something went wrong
    </div>
  </template>
</template>

<style scoped>

</style>