<script>
import {defineComponent} from 'vue'
import OrdersApi from "@/api/orders/orders_api";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";


export default defineComponent({
  name: "CounterpartyCreate",
  emits: ['update'],
  props: {
    product_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      category_list: [],
      counterparty_list: [],
      counterparty_id: null,
      category_id: null,
      price: '',
    }
  },
  components: {
    Multiselect
  },
  methods: {
    async getCategories() {
      let api = new OrdersApi()
      let response = await api.getCategoryList()
      this.category_list = response.results.map(c => {
        return {
          value: c.id,
          label: c.name,
        }
      })
    },
    async getCounterparties() {
      let api = new OrdersApi()
      let response = await api.getCounterpartyList()
      this.counterparty_list = response.results.map(c => {
        return {
          value: c.id,
          label: c.name
        }
      })
    },
    async createCounterparty() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.post(`/counterparty/product/details/${this.product_id}/create/`, {
          price: this.price,
          category_id: this.category_id,
          counterparty_id: this.counterparty_id
        })
        document.querySelector('#CreateShipmentCounterparty .btn-close').click()
        this.$emit('update')
        await Toast.fire({
          icon: "success",
          title: "Shipment Product created",
        })
      } catch (e) {
        await Toast.fire({
          icon: "error",
          title: "Error creating shipment product",
        })
      }
    }
  },
  created() {
    this.getCategories()
    this.getCounterparties()
  }
})
</script>

<template>
  <div id="CreateShipmentCounterparty" class="modal fade" tabindex="-1" aria-hidden="true"
       style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Create Shipment Counterparty
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createCounterparty()">
            <b-row gutter-y="3">
              <b-col sm="12" md="6">
                <label class="form-label">Counterparty</label>
                <Multiselect
                    v-model="counterparty_id" :searchable="true"
                    :options="counterparty_list"></Multiselect>
              </b-col>
              <b-col sm="12" md="6">
                <label class="form-label">Category</label>
                <Multiselect
                    v-model="category_id"
                    :options="category_list"></Multiselect>
              </b-col>
              <b-col sm="12">
                <label class="form-label">Price</label>
                <input
                    step="any"
                    v-model="price" class="form-control" type="number" placeholder="Price">
              </b-col>
              <b-col sm="12" class="mt-3">
                <b-button type="submit" variant="success">Create</b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>