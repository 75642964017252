<script>
import {defineComponent} from "vue";
import axios from "axios";
import CreateShipmentProduct from "../modals/ProductCreate.vue";

export default defineComponent({
  name: "todo",
  emits: ['onSelect'],
  data() {
    return {
      shipmentProducts: [],
      container_type_options: [
        {value: '20', label: '20'},
        {value: '20HC', label: '20HC'},
        {value: '40', label: '40'},
        {value: '40HC', label: '40HC'},
        {value: '45', label: '45'},
      ],
      filter_container_type_value: 'all',
      current_product: {}
    }
  },
  components: {
    CreateShipmentProduct
  },
  methods: {
    async getShipmentProducts() {
      try {
        let response = await axios.get('/counterparty/product/list/')
        this.shipmentProducts = response.data.results
        this.current_product = this.shipmentProducts[0]
        this.$emit('onSelect', this.current_product)
      } catch (e) {
        console.log(e)
      }
    },
    emitItemSelect(product) {
      this.current_product = product
      this.$emit('onSelect', product)
    }
  },
  computed: {
    shipmentProductsComputed() {
      if (this.filter_container_type_value === 'all') return this.shipmentProducts
      return this.shipmentProducts.filter((product) => {
        return product.container_type === this.filter_container_type_value
      })
    }
  },
  created() {
    this.getShipmentProducts()
  }
})
</script>


<template>
  <b-row gutter-y="3">
    <b-col sm="12" lg="7">
      <CreateShipmentProduct @update="getShipmentProducts()"/>
    </b-col>
    <b-col sm="12" lg="5">
      <select
          class="form-control"
          data-choices=""
          data-choices-search-false=""
          v-model="filter_container_type_value"
      >
        <option value="all">All</option>
        <option v-for="container_type in container_type_options"
                :key="container_type"
                :value="container_type.value"
        >
          {{ container_type.label }}
        </option>
      </select>
    </b-col>
    <template v-if="shipmentProductsComputed.length > 0">
      <b-col sm="12" class=""
             v-for="product in shipmentProductsComputed" :key="product">
        <div @click="emitItemSelect(product)"
             :class="{ 'bg-soft-success text-white': current_product.id === product.id}"
             class="d-flex align-items-center justify-content-between rounded-1 border border-dashed border-success cursor-pointer p-2">
          <h6 class="mb-0">
            {{ product.name }}
          </h6>
          <span class="badge badge-gradient-info">
                  {{ product.container_type }}
                </span>
        </div>
      </b-col>
    </template>
    <template v-else>
      <b-col sm="12" class="text-center py-5 text-muted">
        List is empty
      </b-col>
    </template>
  </b-row>
</template>

<style scoped>

</style>